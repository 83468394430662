<template>
  <div>
    <v-text-field
      v-model="currentPropertyValue"
      prepend-icon='mdi-format-font-size-decrease'
      append-outer-icon='mdi-format-font-size-increase'
      type="text"
      outlined
      :readonly="readonly"
      dense
      width="width"
      :reverse="rtl"
      @click:prepend="decreaseElementHeight"
      @click:append-outer="increaseElementHeight"
    ></v-text-field>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ElementHeightComponent',
  props: {
    elementName: null,
    limits: {
      min: { default: 0 },
      max: { default: 100 }
    },
    incrementValue: { default: 1 },
    decrementValue: { default: 1 },
    readonly: { default: true },
    width: { default: 20 },
    initialPropertyValue: { default: 0 }
  },
  data: () => ({
    currentPropertyValue: 0,
    rtl: true
  }),
  methods: {
    increaseElementHeight () {
      if (this.currentPropertyValue + 1 <= this.limits.max) {
        this.currentPropertyValue += this.incrementValue
      }
    },
    decreaseElementHeight () {
      if (this.currentPropertyValue - 1 >= this.limits.min) {
        this.currentPropertyValue -= this.decrementValue
      }
    }
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
