<template>
  <div style="width:100px;">
    <v-tooltip bottom open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click="decrementProperty"
          small
          icon>
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </template>
      <span>
        Decrease {{ propertyName }} of {{ elementName }}
      </span>
    </v-tooltip>
    <span class="subtitle-2 ml-1 mr-1"> {{ currentPropertyValue }}</span>
    <v-tooltip bottom open-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="secondary"
          v-bind="attrs"
          v-on="on"
          @click="incrementProperty"
          small
          icon>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>
        Increase {{ propertyName }} of {{ elementName }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ColumnBorderComponent',
  props: {
    propertyName: null,
    elementName: null,
    limits: {
      min: { default: 0 },
      max: { default: 10 }
    },
    incrementStep: {
      default: 1
    },
    decrementStep: {
      default: 1
    },
    initialPropertyValue: { default: 0 }
  },
  data: () => ({
    currentPropertyValue: 0
  }),
  methods: {
    incrementProperty () {
      if (this.currentPropertyValue + this.incrementStep <= this.limits.max) {
        this.currentPropertyValue += this.incrementStep
        this.returnCurrentValue()
      }
    },
    decrementProperty () {
      if (this.currentPropertyValue - this.decrementStep >= this.limits.min) {
        this.currentPropertyValue -= this.decrementStep
        this.returnCurrentValue()
      }
    },
    returnCurrentValue () {
      // this.$emit('propertyValueChanges', this.currentPropertyValue)
    }
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
