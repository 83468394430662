<template>
   <span v-bind="attrs" v-on="on" :style="marginStyle"  >

     <!-- Empty Region -->
      <span v-if="type === undefined && isEmptyRegion()">
        <span class="d-flex row-editor">
          <v-btn
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
            Empty Region
              <span v-if="region.name" class="pl-3">({{ region.name }})</span>
              <span v-else class="pl-3">(<i>Region needs a name</i>)</span>
          </v-btn>
        </span>

         <span class="edit-column">
          <v-menu
            offset-x
            open-on-hover
            min-width="30"
            :close-on-content-click="false"
            nudge-top="10"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
        >
          <v-list-item class="text-uppercase text-caption">
            Empty Region <span v-if="region.name"> - {{ region.name }}</span>
          </v-list-item>
          <v-list-item class="d-flex justify-start align-start">
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionBefore"
                >
                  <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region before
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="goToRegion(region)"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                Edit <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
             <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateRegion"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>
                Duplicate <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip top v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-model="addRegion"
                  @mouseenter="addRegionMenu=true"
                >
                  <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>
                Insert another region below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('up')"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>
                Move region up
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('down')"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>
                Move region down
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-format-page-break</v-icon>
                </v-btn>
              </template>
              <span>
                      Insert page break below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
                    </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-rotate-left</v-icon>
                </v-btn>
              </template>
              <span>
                Restore <span class="text-uppercase font-weight-bold">{{ region.name }}</span> to original
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-speed-dial
                  v-model="fab"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @mouseenter="addRegionMenu=false"
                    >
                      <v-icon color="important">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    color="grey"
                  >
                    NO
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="important"
                    @click="removeRegion"
                  >
                    YES
                  </v-btn>
                </v-speed-dial>
              </template>
              <span>
                Delete <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionAfter"
                >
                  <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region after
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="addRegionMenu">
            <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
              <v-list>
                <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
          <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
            <v-list-item-title class="grey--text">
              <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        </span>
      </span>

     <span v-if="type === undefined">
       <span v-for="(row, index) in this.region.begin_block.rows" :key="index">
       <span class="d-flex row-editor">
            <agenda-style-region-column-complete :columns="row.columns" :row="row" :rowData="{index: index, part: 'begin_block'}" :rowIndex="index" @rowChanged="rowChanged" />
       </span>
         <span class="edit-column">
          <v-menu
            offset-x
            open-on-hover
            min-width="30"
            :close-on-content-click="false"
            nudge-top="10"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="transparent"
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
        >
          <v-list-item class="text-uppercase text-caption">
            {{ region.name }}
          </v-list-item>
          <v-list-item class="d-flex justify-start align-start">
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionBefore"
                >
                  <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region before
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="goToRegion(region)"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                Edit <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateRegion"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>
                Duplicate <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip top v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-model="addRegion"
                  @mouseenter="addRegionMenu=true"
                >
                  <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>
                Insert another region below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('up')"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>
                Move region up
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('down')"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>
                Move region down
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-format-page-break</v-icon>
                </v-btn>
              </template>
              <span>
                      Insert page break below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
                    </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-rotate-left</v-icon>
                </v-btn>
              </template>
              <span>
                Restore <span class="text-uppercase font-weight-bold">{{ region.name }}</span> to original
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-speed-dial
                  v-model="fab"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @mouseenter="addRegionMenu=false"
                    >
                      <v-icon color="important">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    color="grey"
                  >
                    NO
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="important"
                    @click="removeRegion"
                  >
                    YES
                  </v-btn>
                </v-speed-dial>
              </template>
              <span>
                Delete <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionAfter"
                >
                  <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region after
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="addRegionMenu">
            <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
              <v-list>
                <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
          <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
            <v-list-item-title class="grey--text">
              <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        </span>
      </span>
     </span>

     <span v-if="type === undefined">
       <span v-for="(row, index) in this.region.item_container.rows" :key="index">
       <span class="d-flex row-editor">
            <agenda-style-region-column-complete :columns="row.columns" :row="row" :rowData="{index: index, part: 'item_container'}" :rowIndex="index" @rowChanged="rowChanged" />
       </span>
        <span class="edit-column">
          <v-menu
            offset-x
            open-on-hover
            min-width="30"
            :close-on-content-click="false"
            nudge-top="10"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="transparent"
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
        >
          <v-list-item class="text-uppercase text-caption">
            {{ region.name }}
          </v-list-item>
          <v-list-item class="d-flex justify-start align-start">
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionBefore"
                >
                  <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region before
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="goToRegion(region)"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                Edit <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateRegion"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>
                Duplicate <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip top v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-model="addRegion"
                  @mouseenter="addRegionMenu=true"
                >
                  <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>
                Insert another region below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('up')"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>
                Move region up
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('down')"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>
                Move region down
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-format-page-break</v-icon>
                </v-btn>
              </template>
              <span>
               Insert page break below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-rotate-left</v-icon>
                </v-btn>
              </template>
              <span>
                Restore <span class="text-uppercase font-weight-bold">{{ region.name }}</span> to original
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-speed-dial
                  v-model="fab"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @mouseenter="addRegionMenu=false"
                    >
                      <v-icon color="important">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    color="grey"
                  >
                    NO
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="important"
                    @click="removeRegion"
                  >
                    YES
                  </v-btn>
                </v-speed-dial>
              </template>
              <span>
                Delete <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionAfter"
                >
                  <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region after
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="addRegionMenu">
            <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
              <v-list>
                <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
          <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
            <v-list-item-title class="grey--text">
              <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        </span>
     </span>
     </span>

     <span v-if="type === undefined">
       <span v-for="(row, index) in this.region.end_block.rows" :key="index">
       <span class="d-flex row-editor">
         <agenda-style-region-column-complete :columns="row.columns" :row="row" :rowData="{index: index, part: 'end_block'}" :rowIndex="index" @rowChanged="rowChanged" />
       </span>
        <span class="edit-column">
          <v-menu
            offset-x
            open-on-hover
            min-width="30"
            :close-on-content-click="false"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="transparent"
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
        >
          <v-list-item class="text-uppercase text-caption">
            {{ region.name }}
          </v-list-item>
          <v-list-item class="d-flex justify-start align-start">
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionBefore"
                >
                  <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region before
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="goToRegion(region)"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                Edit <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateRegion"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>
                Duplicate <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip top v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-model="addRegion"
                  @mouseenter="addRegionMenu=true"
                >
                  <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>
                Insert another region below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('up')"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>
                Move region up
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('down')"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>
                Move region down
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-format-page-break</v-icon>
                </v-btn>
              </template>
              <span>
                Insert page break below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-rotate-left</v-icon>
                </v-btn>
              </template>
              <span>
                Restore <span class="text-uppercase font-weight-bold">{{ region.name }}</span> to original
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-speed-dial
                  v-model="fab"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @mouseenter="addRegionMenu=false"
                    >
                      <v-icon color="important">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    color="grey"
                  >
                    NO
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="important"
                    @click="removeRegion"
                  >
                    YES
                  </v-btn>
                </v-speed-dial>
              </template>
              <span>
                Delete <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionAfter"
                >
                  <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region after
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="addRegionMenu">
            <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
              <v-list>
                <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
          <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
            <v-list-item-title class="grey--text">
              <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        </span>
     </span>
     </span>

     <span v-if="type === undefined">
       <span v-for="(row, index) in this.region.no_item_text.rows" :key="index">
       <span class="d-flex row-editor">
         <agenda-style-region-column-complete :columns="row.columns" :row="row" :rowData="{index: index, part: 'no_item_text'}" :rowIndex="index" @rowChanged="rowChanged" />
       </span>
        <span class="edit-column">
          <v-menu
            offset-x
            open-on-hover
            min-width="30"
            :close-on-content-click="false"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            color="transparent"
            tile
            block
            @click="goToRegion(region)"
            height="100%"
          >
            <v-icon>mdi-vertical-dots</v-icon>
          </v-btn>
        </template>
        <v-list
          dense
        >
          <v-list-item class="text-uppercase text-caption">
            {{ region.name }}
          </v-list-item>
          <v-list-item class="d-flex justify-start align-start">
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionBefore"
                >
                  <v-icon color="secondary">mdi-table-arrow-up</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region before
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="goToRegion(region)"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>
                Edit <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="duplicateRegion"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>
                Duplicate <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip top v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-model="addRegion"
                  @mouseenter="addRegionMenu=true"
                >
                  <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                </v-btn>
              </template>
              <span>
                Insert another region below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('up')"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
              </template>
              <span>
                Move region up
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="moveRegion('down')"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <span>
                Move region down
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-format-page-break</v-icon>
                </v-btn>
              </template>
              <span>
                Insert page break below <span class="text-uppercase font-weight-bold">{{ region.name }}</span>
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='meeting'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @mouseenter="addRegionMenu=false"
                >
                  <v-icon>mdi-rotate-left</v-icon>
                </v-btn>
              </template>
              <span>
                Restore <span class="text-uppercase font-weight-bold">{{ region.name }}</span> to original
              </span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-speed-dial
                  v-model="fab"
                >
                  <template v-slot:activator>
                    <v-btn
                      v-model="fab"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      @mouseenter="addRegionMenu=false"
                    >
                      <v-icon color="important">
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-btn
                    fab
                    dark
                    small
                    color="grey"
                  >
                    NO
                  </v-btn>
                  <v-btn
                    fab
                    dark
                    small
                    color="important"
                    @click="removeRegion"
                  >
                    YES
                  </v-btn>
                </v-speed-dial>
              </template>
              <span>
                Delete <span class="text-uppercase font-weight-bold">{{ region.name }}</span> region
              </span>
            </v-tooltip>
            <v-tooltip bottom v-if="builderType==='agenda'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="insertRegionAfter"
                >
                  <v-icon color="secondary">mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>
                Insert region after
              </span>
            </v-tooltip>
          </v-list-item>
          <v-list-item v-if="addRegionMenu">
            <v-card elevation="0" max-height="300" width="100%" style="overflow-y: auto;">
              <v-list>
                <v-list-item v-for="(item, i) in testRegions" :key="i" @click="testAddRegion">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-list-item>
          <v-list-item class="pa-7 py-0 pb-4" v-if="addRegionMenu" @click="testAddRegion">
            <v-list-item-title class="grey--text">
              <v-icon color="grey">mdi-border-none-variant</v-icon> Blank region
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        </span>
     </span>
     </span>
     <!-- This displays the single blocks -->
     <span v-if="type !== undefined">
       <span v-if="regionType(this.type)">
         <span v-for="(row, index) in regionType(this.type)" :key="index">
           <span class="d-flex row-editor">
              <div class="d-flex" style="position: relative">
                <agenda-style-region-column-complete :columns="row.columns" :row="row" :rowData="{index: index, part: 'blank_part'}" :rowIndex="index" @rowChanged="rowChanged" />
              </div>
           </span>
         </span>
       </span>
       <span v-else class="empty-region-part">
         <!-- Add a menu here with the choice to add a row (that has exactly one, full-width column) -->
                <agenda-style-region-empty-part-complete :part="this.type"/>
       </span>
     </span>

  </span>
</template>

<script>
import Vue from 'vue'
// import AgendaStyleRegionColumn from '@/components/AgendaStyles/Regions/AgendaStyleRegionColumn'
import AgendaStyleRegionColumnComplete from '@/components/AgendaStyles/Regions/AgendaStyleRegionColumnComplete'
import AgendaStyleRegionEmptyPartComplete from '@/components/AgendaStyles/Regions/AgendaStyleRegionEmptyPartComplete'
import { mapState } from 'vuex'
import axios from 'axios'
// import { region } from '@/store/modules/region'

export default Vue.extend({
  name: 'AgendaStyleRegionComponent',
  components: {
    // AgendaStyleRegionColumn
    AgendaStyleRegionColumnComplete,
    AgendaStyleRegionEmptyPartComplete
  },
  props: {
    region: {
      type: Object,
      required: false
    },
    regionKey: {
      type: String,
      required: false
    },
    addRegionMenu: {
      type: Boolean
    },
    type: {
      type: String
    },
    testRegions: {},
    on: {},
    attrs: {},
    value: {
      required: false,
      type: Boolean
    },
    settings: {}
  },
  data: () => ({
    fab: false
  }),
  computed: {
    ...mapState('region', ['selectedRegion', 'builderType', 'previewRegions', 'previewRegion', 'regions']),
    regions: {
      get () {
        return this.$store.state.region.regions
      },
      set (value) {
        this.$store.dispatch('region/setRegions', value)
      }
    },
    marginStyle () {
      const margin = JSON.parse(JSON.stringify(this.settings.margins))
      return {
        'margin-top': margin.top + 'in',
        'margin-right': margin.right + 'in',
        'margin-bottom': margin.bottom + 'in',
        'margin-left': margin.left + 'in'
      }
    }
  },
  methods: {
    testAddRegion () {
      console.log('Add Test Region')
    },
    goToRegion (region) {
      this.$store.dispatch('region/setSelectedRegion', region)
      this.$emit('goToRegion', region)
    },
    goToRow (row, index) {
      console.log('index: ' + index)
      this.$store.dispatch('region/setSelectedRow', row)
      console.log('row: ' + row)
      this.$emit('goToRow', row, index - 1)
    },
    insertRowBefore (value) {
      console.log(value)
      // Wasn't accounting for adding above first row
      // value = (value === 0 ? 1 : value)
      this.insertRowAtIndex(value - 1)
    },
    insertRowAfter (value) {
      console.log(value)
      this.insertRowAtIndex(value)
    },
    async insertRegionBefore () {
      let regionVals = Object.entries(this.regions)
      const emptyRegion = await this.getEmptyRegion()
      const emptyRegionKey = Object.keys(emptyRegion).pop()
      const currentIndex = regionVals.findIndex(region => region[0] === this.regionKey)
      if (currentIndex === 0) {
        regionVals = [[emptyRegionKey, emptyRegion[emptyRegionKey]], ...regionVals]
      } else {
        regionVals.splice(currentIndex, 0, [emptyRegionKey, emptyRegion[emptyRegionKey]])
      }
      const newRegionObj = {}
      regionVals.forEach((region, index) => {
        newRegionObj[index + 1] = region[1]
      })
      this.regions = newRegionObj
      console.log('this regions', this.regions)
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    async insertRegionAfter () {
      const regionVals = Object.entries(this.regions)
      const emptyRegion = await this.getEmptyRegion()
      const emptyRegionKey = Object.keys(emptyRegion).pop()
      const currentIndex = regionVals.findIndex(region => region[0] === this.regionKey)
      regionVals.splice(currentIndex + 1, 0, [emptyRegionKey, emptyRegion[emptyRegionKey]])

      const newRegionObj = {}
      regionVals.forEach((region, index) => {
        newRegionObj[index + 1] = region[1]
      })
      this.regions = newRegionObj
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    async getEmptyRegion () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-region')
      return response.data
    },
    removeRow (index) {
      Vue.delete(this.selectedRegion[this.type].rows, parseInt(index))
      const newRowsObj = {}
      const rows = this.selectedRegion[this.type].rows
      const rowVals = Object.values(rows)

      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })
      this.selectedRegion[this.type].rows = newRowsObj
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    moveRow (index, direction) {
      const originalIndex = index - 1
      const rows = this.selectedRegion[this.type].rows
      const rowVals = Object.values(rows)
      const newIndex = direction === 'up' ? originalIndex - 1 : originalIndex + 1
      const newRowsArr = this.move(rowVals, originalIndex, newIndex)

      const newRowsObj = {}
      newRowsArr.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.selectedRegion[this.type].rows = newRowsObj
      this.$store.dispatch('region/setSelectedRegion', this.selectedRegion)
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    moveRegion (direction) {
      const regionVals = Object.entries(this.regions)
      const currentIndex = regionVals.findIndex(region => region[0] === this.regionKey)
      const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1
      const newRegionsArr = this.move(regionVals, currentIndex, newIndex)
      const newRegionsObj = {}

      newRegionsArr.forEach((region, index) => {
        newRegionsObj[index + 1] = region[1]
      })

      this.regions = newRegionsObj
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    },
    removeRegion () {
      Vue.delete(this.regions, this.regionKey)
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    duplicateRegion () {
      let regionVals = Object.entries(this.regions)
      const currentIndex = regionVals.findIndex(region => region[0] === this.regionKey)
      const newRegion = Object.assign({}, this.region)
      newRegion.name = 'Copy of - ' + this.regions[this.regionKey].name
      regionVals.splice(currentIndex + 1, 0, [parseInt(this.regionKey) + 1, newRegion])
      regionVals = JSON.parse(JSON.stringify(regionVals))

      const newRegionObj = {}
      regionVals.forEach((region, index) => {
        newRegionObj[index + 1] = region[1]
      })

      this.regions = newRegionObj
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    duplicateRow (index) {
      const rows = this.selectedRegion[this.type].rows
      const newRow = Object.assign({}, rows[index])
      let rowVals = Object.values(rows)
      const newRowsObj = {}

      rowVals.splice(index, 0, newRow)
      rowVals = JSON.parse(JSON.stringify(rowVals))
      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.selectedRegion[this.type].rows = newRowsObj
      this.$store.dispatch('region/setSelectedRegion', this.selectedRegion)
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    move (array, from, to) {
      if (to === from) return array
      if (array.length - 1 < to) return array
      if (to < 0) return array

      const target = array[from]
      const increment = to < from ? -1 : 1

      for (let k = from; k !== to; k += increment) {
        array[k] = array[k + increment]
      }
      array[to] = target
      return array
    },
    async insertRowAtIndex (index) {
      const emptyRow = await this.getEmptyRow()
      const rows = this.selectedRegion[this.type].rows

      const rowVals = Object.values(rows)
      rowVals.splice(index, 0, emptyRow)

      const newRowsObj = {}
      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.selectedRegion[this.type].rows = newRowsObj
      await this.$store.dispatch('region/setSelectedRegion', this.selectedRegion)
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    async getEmptyRow () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-row')
      return response.data
    },
    isEmptyRegion () {
      const beginBlockRowsLength = Object.keys(this.region.begin_block?.rows ?? []).length
      const endBlockRowsLength = Object.keys(this.region.end_block?.rows ?? []).length
      const itemContainerRowsLength = Object.keys(this.region.item_container?.rows ?? []).length
      const noItemTextRowsLength = Object.keys(this.region.no_item_text?.rows ?? []).length

      return beginBlockRowsLength === 0 &&
        endBlockRowsLength === 0 &&
        itemContainerRowsLength === 0 &&
        noItemTextRowsLength === 0
    },
    regionType (type) {
      if (type === 'begin_block' && this.checkRegionLength('begin_block')) {
        return this.selectedRegion.begin_block.rows
      }

      if (type === 'end_block' && this.checkRegionLength('end_block')) {
        return this.selectedRegion.end_block.rows
      }

      if (type === 'item_container' && this.checkRegionLength('item_container')) {
        return this.selectedRegion.item_container.rows
      }

      if (type === 'no_item_text' && this.checkRegionLength('no_item_text')) {
        return this.selectedRegion.no_item_text.rows
      }
    },
    checkRegionLength (type) {
      if (type === undefined) {
        return Object.keys(this.region[type]).length
      } else {
        return Object.keys(this.selectedRegion).length
      }
    },
    rowChanged (rowData) {
      console.log('changing: ' + rowData.index + ' in the ' + rowData.part)
    }
  },
  mounted () {
    if (this.region && Object.keys(this.region).length === 0) {
      console.log('should set')
      // this.region = this.$store.region.region
      console.log('region', this.region)
    }
    // console.log('mounted')
    // this.region = this.$store.region.selectedRegion
  },
  beforeDestroy () {
    console.log('comp destry')
  }
})
</script>
