<template>
  <v-card-text>

    <v-row>
      <v-col cols="5" class="pr-8">
        <v-row>
          <v-col>
            <v-text-field
              outlined
              label="Name"
              v-model="settingName"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              inset
              label="Can contain items"
              v-model="settingContainItems"
              color="secondary"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              inset
              label="Include in minutes"
              v-model="settingIncludeMinutes"
              color="secondary"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row v-if="builderType === 'agenda'">
          <v-col>
            <v-switch
              inset
              label="Include in agenda by default"
              v-model="settingIncludeInAgenda"
              color="secondary"
            ></v-switch>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              inset
              label="Reset Numbering for each numbering group"
              v-model="settingResetNumberingEachGroup"
              color="secondary"
            ></v-switch>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="pa-0">
        <v-row>
          <v-col
            cols="7"
            class="pr-8"
          >
            <v-row>
              <v-col>
                <v-select
                  label="Region Numbering"
                  outlined
                  small
                  hide-details
                  v-model="settingNumberRegion"
                  :items="numberingFilter"
                  item-value="value"
                  item-text="type"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Great Numbering"
                  outlined
                  hide-details
                  v-model="settingNumberGreat"
                  :items="numberingType"
                  item-value="value"
                  item-text="type"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Grand Numbering"
                  outlined
                  hide-details
                  v-model="settingNumberGrand"
                  :items="numberingType"
                  item-value="value"
                  item-text="type"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="Parent Numbering"
                  outlined
                  hide-details
                  v-model="settingNumberParent"
                  :items="numberingType"
                  item-value="value"
                  item-text="type"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select
                  label="First Numbering"
                  outlined
                  hide-details
                  v-model="settingNumberFirst"
                  :items="numberingType"
                  item-value="value"
                  item-text="type"
                  :menu-props="{ top: true, offsetY: true }"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="5"
            class="grey lighten-3 mt-6 mb-6 pa-8"
          >
            <span class="text-uppercase font-weight-bold d-flex mb-6">
              Numbering Sample
            </span>
            <span class="d-flex mb-3" v-if="symbolExistsFromType('region')">
              <span v-text="getSymbolFromType('region')" class="mr-2"></span>Region
            </span>
            <span class="d-flex mb-3 ml-2" v-if="symbolExistsFromType('great')">
              <span v-text="getSymbolFromType('great')" class="mr-2"></span> Great
            </span>
            <span class="d-flex mb-3 ml-4" v-if="symbolExistsFromType('grand')">
              <span v-text="getSymbolFromType('grand')" class="mr-2"></span> Grant
            </span>
            <span class="d-flex mb-3 ml-6" v-if="symbolExistsFromType('parent')">
              <span v-text="getSymbolFromType('parent')" class="mr-2"></span> Parent
            </span>
            <span class="d-flex mb-3 ml-8" v-if="symbolExistsFromType('first')">
              <span v-text="getSymbolFromType('first')" class="mr-2"></span> First
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'AgendaStyleRegionSettings',
  data: () => ({
    settingsForm: {
      name: '',
      agenda_style_id: '',
      contain_items: false,
      include_minutes: false,
      include_in_agenda: false,
      reset_numbering_each_group: false,
      region: '',
      great: '',
      grand: '',
      parent: '',
      first: ''
    },
    numberingType: [
      { value: 'decimal', type: 'Decimal', sample: '1,2,3' },
      { value: 'alpha_cap_cycle', type: 'Capital Alphabet Cycle', sample: 'A,B,C...AA,AB,AC' },
      { value: 'alpha_low_cycle', type: 'Lowercase Alphabet Cycle', sample: 'a,b,c...aa,ab,ac' },
      { value: 'alpha_cap_repeat', type: 'Capital Alphabet Repeat', sample: 'A,B,C...AA,BB,CC' },
      { value: 'alpha_low_repeat', type: 'Lowercase Alphabet Repeat', sample: 'a,b,c...aa,bb,cc' },
      { value: 'roman_cap', type: 'Capital Roman', sample: 'I,II,II' },
      { value: 'roman_low', type: 'Lowercase Roman', sample: 'i,ii,iii' },
      { value: 'circle', type: 'Circle', sample: '●' },
      { value: 'square', type: 'Square', sample: '■' },
      { value: 'disc', type: 'Disc', sample: '○' },
      { value: 'diamond', type: 'Diamond', sample: '◆' },
      { value: '', type: 'Same as previous region', sample: '', exclude: true }
    ],
    numberingSelected: [
      { selected: '1.', symbol: '1.' },
      { selected: 'A.', symbol: 'A.' },
      { selected: 'a.', symbol: 'a.' },
      { selected: 'I.', symbol: 'I.' },
      { selected: '●', symbol: '●' }
    ]
  }),
  computed: {
    ...mapState('region', ['selectedRegion', 'builderType']),
    settingName: {
      get () {
        return this.selectedRegion.name
      },
      set (val) {
        this.selectedRegion.name = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingContainItems: {
      get () {
        return this.selectedRegionOptionProperty('contain_items')
      },
      set (val) {
        this.selectedRegion.options.contain_items = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingIncludeMinutes: {
      get () {
        return this.selectedRegionOptionProperty('include_minutes')
      },
      set (val) {
        this.selectedRegion.options.include_minutes = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingIncludeInAgenda: {
      get () {
        return this.selectedRegionOptionProperty('include_in_agenda')
      },
      set (val) {
        this.selectedRegion.options.include_in_agenda = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingResetNumberingEachGroup: {
      get () {
        return this.selectedRegionOptionProperty('reset_numbering_each_group')
      },
      set (val) {
        this.selectedRegion.options.reset_numbering_each_group = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberRegion: {
      get () {
        return this.selectedRegion?.numbering?.region
      },
      set (val) {
        this.selectedRegion.numbering.region = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberGreat: {
      get () {
        return this.selectedRegion?.numbering?.great
      },
      set (val) {
        this.selectedRegion.numbering.great = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberGrand: {
      get () {
        return this.selectedRegion?.numbering?.grand
      },
      set (val) {
        this.selectedRegion.numbering.grand = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberParent: {
      get () {
        return this.selectedRegion?.numbering?.parent
      },
      set (val) {
        this.selectedRegion.numbering.parent = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    settingNumberFirst: {
      get () {
        return this.selectedRegion?.numbering?.first
      },
      set (val) {
        this.selectedRegion.numbering.first = val
        this.$store.dispatch('region/setContentHasBeenModified')
      }
    },
    numberingFilter () {
      return this.numberingType.filter(item => item.exclude === undefined)
    }
  },
  watch: {
    settingNumberRegion: {
      handler () {
        if (this.selectedRegion.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberGreat: {
      handler () {
        if (this.selectedRegion.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberGrand: {
      handler () {
        if (this.selectedRegion.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberParent: {
      handler () {
        if (this.selectedRegion.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    },
    settingNumberFirst: {
      handler () {
        if (this.selectedRegion.numbering !== undefined) {
          this.numberSelectedHandle()
        }
      },
      deep: true
    }
  },
  created () {
    // this.watchCollection(
    //   ['param.a', 'param.b', 'param.c'],
    //   this.calc)
  },
  methods: {
    getSymbolFromType (type) {
      const value = this.selectedRegion.numbering[type]
      if (!value) {
        return ''
      }
      const obj = this.numberingType.find(o => o.value === value)
      return obj.sample
    },
    selectedRegionOptionProperty (property) {
      if (this.selectedRegion.options && this.selectedRegion.options[property]) {
        return this.selectedRegion.options[property]
      }
      return ''
    },
    numberSelectedHandle () {
      for (let i = 0; i < 5; i++) {
        if (this.numberingSelected[i].selected === 'previous') {
          this.numberingSelected[i].symbol = this.numberingSelected[i - 1].symbol
        } else {
          this.numberingSelected[i].symbol = this.numberingSelected[i].selected
        }
      }
    },
    watchCollection (arr, cb) {
      arr.forEach((val) => this.$watch(val, cb))
    },
    symbolExistsFromType (type) {
      if (this.selectedRegion.numbering && this.selectedRegion.numbering[type]) {
        return this.selectedRegion.numbering[type].length > 0
      }
      return false
    },
    getNumberingValueFromType (type) {
      return this.selectedRegion.numbering[type]
    }
  }
})
</script>
