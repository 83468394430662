<template>
  <div class="d-flex" style="position: relative; height:100%;">
    <div class="edit-cell d-flex align-center" style="width:100%;">
      <span class="edit-column">
        <v-menu
          offset-y
          open-on-hover
          min-width="30"
          nudge-right="320"
          top
          :close-on-content-click=false
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="transparent"
              tile
              block
              @click="goToRow(row, index)"
            >
              <v-icon>mdi-vertical-dots</v-icon>
            </v-btn>
          </template>
          <v-list
            dense
          >
            <v-list-item class="d-flex justify-left">
              <v-tooltip bottom open-delay="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="insertRowBefore(index)"
                  >
                    <v-icon color="secondary">mdi-plus-thick</v-icon>
                  </v-btn>
                </template>
                <span>
                  Add a row to the <b>{{ regionPartNames[part] }} part</b>
                </span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-menu>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'

export default Vue.extend({
  name: 'AgendaStyleRegionColumn',
  props: {
    row: {},
    columns: {},
    part: {}
  },
  data: () => ({
    regionPartNames: {
      begin_block: 'Begin Block',
      item_container: 'Item Container',
      no_item_text: 'No Item Text',
      end_block: 'End Block'
    }
  }),
  computed: {
    ...mapState('region', ['previewRegions', 'selectedRegion'])
  },
  methods: {
    displayContent (column) {
      if (column.parsedContent !== undefined && column.parsedContent.length > 0) return column.parsedContent
      return column.content
    },
    insertRowAfter (value) {
      console.log(value)
      this.insertRowAtIndex(value)
    },
    insertRowBefore () {
      console.log('inserting row before')
    },
    async insertRowAtIndex (index) {
      const emptyRow = await this.getEmptyRow()
      const rows = this.selectedRegion[this.type].rows

      const rowVals = Object.values(rows)
      rowVals.splice(index, 0, emptyRow)

      const newRowsObj = {}
      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.selectedRegion[this.type].rows = newRowsObj
      await this.$store.dispatch('region/setSelectedRegion', this.selectedRegion)
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    async getEmptyRow () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-row')
      return response.data
    }
  }
})
</script>
