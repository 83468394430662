<template>

  <v-dialog
    width="900"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">

      <v-card-title class="d-flex justify-center headline flex-column mb-6 align-start">
        <span>Previous Versions of <span class="font-weight-black">{{agendaStyle.name}}</span></span>
      </v-card-title>

      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="show=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>
      <v-card-text>
        <v-card-text class="white text--primary text-center" v-if="updatedVersions.length === 0 && !loading">
          No Versions Have Been Created Yet
        </v-card-text>
        <v-timeline align-top v-else>
          <v-timeline-item
            v-for="(version, versionKey) in updatedVersions"
            :key="versionKey"
            fill-dot
            :color="version.iconColor"
            :icon="version.icon"
          >
            <v-card>
              <v-card-text class="white text--primary">
                {{ version.text }} version on <b>{{ versionPublishedInfo[version.key]['formatted_date'] }}</b> by <b>{{ versionPublishedInfo[version.key]['published_by'] }}</b>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <v-timeline-item
            v-if="createdVersion"
            fill-dot
            color="primary"
            icon="mdi-file-outline"
          >
            <v-card>
              <v-card-text class="white text--primary">
                Created on <b>{{ versionPublishedInfo[createdVersion.key]['formatted_date'] }}</b> by <b>{{ versionPublishedInfo[createdVersion.key]['published_by'] }}</b>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-card-actions class="d-flex align-center justify-center">
        <v-spacer></v-spacer>
        <v-btn depressed width="130" @click="show=false">Ok</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default Vue.extend({
  name: 'AgendaStylePreviousVersions',
  props: {
    agendaStyle: {},
    value: {
      required: true,
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    versions: [],
    versionPublishedInfo: [],
    activeVersion: null,
    draftVersion: null
  }),
  computed: {
    orderedVersions () {
      const orderedVersions = []
      for (const versionDate in this.versions) {
        const version = this.versions[versionDate]
        version.key = versionDate
        version.icon = 'mdi-update'
        version.iconColor = 'secondary'
        version.text = 'Revised'

        if (versionDate === this.activeVersion) {
          version.icon = 'mdi-star-shooting-outline'
          version.iconColor = 'accentGreen'
          version.text = 'Current'
        }

        if (versionDate !== this.draftVersion) {
          orderedVersions.push(version)
        }
      }

      orderedVersions.sort((a, b) => (a.key < b.key) ? 1 : -1)
      return orderedVersions
    },
    updatedVersions () {
      return [...this.orderedVersions].slice(0, -1)
      // return this.versions
    },
    createdVersion () {
      return [...this.orderedVersions].pop()
      /*
      const createdVersion = this.versions[Object.keys(this.versions)[0]]
      // return this.versions[Object.keys(this.versions)[0]]
      createdVersion.key = Object.keys(this.versions)[0]
      return createdVersion
      */
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.loading = true
        this.activeVersion = this.agendaStyle.template_versions.active_version
        this.draftVersion = this.agendaStyle.template_versions.draft_version
        this.versions = JSON.parse(this.agendaStyle.template_versions.versions)
        this.versionPublishedInfo = this.agendaStyle.template_versions.published_info
        console.log(this.versions)
        this.loading = false
        /*
        axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/get-published-versions/' + this.agendaStyle.id)
          .then((res) => {
            this.versions = res.data
            this.loading = false
          })
        */
      }
    }
  }
})
</script>
