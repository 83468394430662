<template>
  <div>
    <v-text-field
      v-model="currentPropertyValue"
      prepend-icon='mdi-format-font-size-decrease'
      append-outer-icon='mdi-format-font-size-increase'
      type="text"
      outlined
      :readonly="readonly"
      dense
      width="width"
      :reverse="rtl"
      @click:prepend="decreaseFontSize"
      @click:append-outer="increaseFontSize"
    ></v-text-field>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FontSizeComponent',
  props: {
    elementName: null,
    limits: {
      min: { default: 6 },
      max: { default: 72 }
    },
    incrementValue: { default: 1 },
    decrementValue: { default: 1 },
    readonly: { default: true },
    width: { default: 20 },
    rtl: { default: true },
    initialPropertyValue: { default: 8 }
  },
  data: () => ({
    currentPropertyValue: []
  }),
  methods: {
    increaseFontSize () {
      if (this.currentPropertyValue + 1 <= this.limits.max) {
        this.currentPropertyValue += this.incrementValue
      }
    },
    decreaseFontSize () {
      if (this.currentPropertyValue - 1 >= this.limits.min) {
        this.currentPropertyValue -= this.decrementValue
      }
    }
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
