<template>
  <div>
    <v-btn-toggle
      v-model="currentPropertyValue"
      dense
      group
      multiple
    >
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="bold"
          >
            <v-icon color="secondary">mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>
          Bold {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="italic"
          >
            <v-icon color="secondary">mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>
          Italicize {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="underline"
          >
            <v-icon color="secondary">mdi-format-underline</v-icon>
          </v-btn>
        </template>
        <span>
          Underline {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="uppercase"
          >
            <v-icon color="secondary">mdi-format-letter-case-upper</v-icon>
          </v-btn>
        </template>
        <span>
          Make {{ elementName }} uppercase
        </span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FontStyleComponent',
  props: {
    elementName: null,
    initialPropertyValue: { default: [] }
  },
  data: () => ({
    currentPropertyValue: []
  }),
  methods: {
    /*
    incrementProperty () {
      if (this.currentPropertyValue + this.incrementStep <= this.limits.max) {
        this.currentPropertyValue++
        this.returnCurrentValue()
      }
    },
    decrementProperty () {
      if (this.currentPropertyValue - this.decrementStep >= this.limits.min) {
        this.currentPropertyValue--
        this.returnCurrentValue()
      }
    },
    returnCurrentValue () {
      console.log('current value:' + this.currentPropertyValue)
      // this.$emit('propertyValueChanges', this.currentPropertyValue)
    }
    */
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
