var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn-toggle',{attrs:{"dense":"","group":"","multiple":""},model:{value:(_vm.currentPropertyValue),callback:function ($$v) {_vm.currentPropertyValue=$$v},expression:"currentPropertyValue"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"bold"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-bold")])],1)]}}])},[_c('span',[_vm._v(" Bold "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"italic"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-italic")])],1)]}}])},[_c('span',[_vm._v(" Italicize "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"underline"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-underline")])],1)]}}])},[_c('span',[_vm._v(" Underline "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"uppercase"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-letter-case-upper")])],1)]}}])},[_c('span',[_vm._v(" Make "+_vm._s(_vm.elementName)+" uppercase ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }