<template>
  <div class="d-flex" style="position: relative">
    <div class="edit-cell d-flex align-center"
         :class="{ 'table-preview' : previewRegions }"
         v-for="(column, index) in columns" :key="index"
         :style="{width: column.width + 'in'}"
    >
              <span class="edit-column">
                <v-menu
                  offset-y
                  open-on-hover
                  min-width="30"
                  top
                  :close-on-content-click=false
                  open-delay="500"
                  close-delay="650"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="transparent"
                      tile
                      block
                    >
                      <v-icon>mdi-vertical-dots</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                    ref="columnMenuContent"
                  >
                    <v-list-item class="d-flex justify-left"
                      v-if="!formatMenuVisible && !borderMenuVisible">
                        <span style="width:50px;" class="text-caption">
                          Row
                        </span>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="insertRowBefore(index)"
                          >
                            <v-icon color="secondary">mdi-table-row-plus-before</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Insert row before
                        </span>
                      </v-tooltip>
                      <!--
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="goToRow(row, index)"
                          >
                            <v-icon color="success lighten-1">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Edit row
                        </span>
                      </v-tooltip>
                      -->
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="duplicateRow(index)"
                            @mouseenter="addRegionMenu=false"
                          >
                            <v-icon color="purple lighten-2">mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Duplicate Row
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="moveRow(index, 'up')"
                          >
                            <v-icon>mdi-chevron-up</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Move row up
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="moveRow(index, 'down')"
                          >
                            <v-icon>mdi-chevron-down</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Move row down
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <!---<v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="rowHasChanged(rowData)"
                          >
                            <v-icon color="important">mdi-delete</v-icon>
                          </v-btn>--->
                            <v-speed-dial
                              v-model="fab"
                            >
                              <template v-slot:activator>
                                <v-btn
                                  v-model="fab"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="important">
                                    mdi-delete
                                  </v-icon>
                                </v-btn>
                              </template>
                              <v-btn
                                fab
                                dark
                                small
                                color="grey"
                              >
                                NO
                              </v-btn>
                              <v-btn
                                fab
                                dark
                                small
                                color="important"
                                @click="rowHasChanged(rowData)"
                              >
                                YES
                              </v-btn>
                            </v-speed-dial>
                        </template>
                        <span>
                          Delete row
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="insertRowAfter(index)"
                          >
                            <v-icon color="secondary">mdi-table-row-plus-after</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Insert row after
                        </span>
                      </v-tooltip>
                    </v-list-item>
                    <v-list-item
                      class="d-flex justify-left"
                      v-if="formatMenuVisible">
                      <v-card
                        outlined
                        class="mb-2">
                        <v-card-text>
                        <h3>Format Menu</h3>
                          <v-row>
                            <v-col class="mb-0">
                              <div>Font Size</div>
                              <font-size-component
                                width="10"
                                :initialPropertyValue="14"
                                :limits="{ min: 6, max: 48 }"
                                :incrementStep="1"
                                :decrementStep="1"></font-size-component>
                              <!--
                                <v-text-field
                                  v-model="fontSize"
                                  prepend-icon='mdi-format-font-size-decrease'
                                  append-outer-icon='mdi-format-font-size-increase'
                                  type="text"
                                  outlined
                                  readonly
                                  dense
                                  width="20"
                                  reverse
                                  @click:prepend="decreaseFontSize"
                                  @click:append-outer="increaseFontSize"
                                ></v-text-field>
                              -->
                            </v-col>
                            <v-col>
                              <div>Font Name</div>
                              <font-name-component elementName="all cell contents" initialPropertyValue="Arial"></font-name-component>
                              <!--
                                <v-select
                                  :items="fontFamilies"
                                  v-model="fontFamily"
                                  dense
                                  outlined
                                ></v-select>
                              -->
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <div>Horizontal Alignment</div>
                              <horizontal-alignment-component elementName="all cell content"></horizontal-alignment-component>
                              <!--
                              <v-btn-toggle
                                v-model="columnAlign.horizonal"
                                dense
                                group>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-align-left</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Left-align all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-align-center</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Center all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-align-right</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Right-align all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-align-justify</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Justify all cell content
                                  </span>
                                </v-tooltip>
                              </v-btn-toggle>
                              -->
                            </v-col>
                            <v-col>
                              <div>Font Style</div>
                              <font-style-component
                                elementName="all cell content"
                                :initialPropertyValue="['bold', 'uppercase']"
                              ></font-style-component>
                              <!--
                              <v-btn-toggle
                                v-model="columnAlign.horizonal"
                                dense
                                group
                                multiple
                                >
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-bold</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Bold all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-italic</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Italicize all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-underline</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Underline all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                      small
                                    >
                                      <v-icon color="secondary">mdi-format-letter-case-upper</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Make all cell content uppercase
                                  </span>
                                </v-tooltip>
                              </v-btn-toggle>
                              -->
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <div>Vertical Alignment</div>
                              <vertical-alignment-component initialPropertyValue="middle" elementName="all cell content"></vertical-alignment-component>
                              <!--
                              <v-btn-toggle
                                v-model="columnAlign.vertical"
                                class=""
                                dense
                                group>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="secondary">mdi-format-align-top</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Top-align all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="secondary">mdi-format-align-middle</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Middle-align all cell content
                                  </span>
                                </v-tooltip>
                                <v-tooltip bottom open-delay="500">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      icon
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <v-icon color="secondary">mdi-format-align-bottom</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>
                                    Bottom-align all cell content
                                  </span>
                                </v-tooltip>
                              </v-btn-toggle>
                              -->
                            </v-col>
                            <v-col>
                              <div>Text Color</div>
                              <color-picker-component :initialPropertyValue="{ r: 255, g: 0, b: 0 }" elementName="all cell content" :hideCanvas="false"></color-picker-component>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item>
                    <v-list-item
                      class="d-flex justify-left"
                      v-if="borderMenuVisible">
                      <v-card
                        outlined
                        class="mb-2">
                        <v-card-text>
                          <h3>Cell Border and Padding Menu</h3>
                          <v-row>
                                <v-col cols="4">
                                  <v-card
                                    outlined
                                    class="mb-2">
                                    <v-card-text>
                                              <p>Cell Border</p>
                                              <v-row>
                                                <v-col cols="4">
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Top
                                              <column-border-component
                                                propertyName="width"
                                                elementName="top border"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                  <!--
                                                <v-text-field
                                                  v-model="borderWidth.top"
                                                  type="text"
                                                  outlined
                                                  readonly
                                                  dense
                                                  reverse
                                                ></v-text-field>
                                                  -->
                                                </v-col>
                                                <v-col cols="4">
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="4" class="subtitle-2">Left
                                              <column-border-component
                                                propertyName="width"
                                                elementName="left border"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                  <!--
                                                <v-text-field
                                                  v-model="borderWidth.left"
                                                  type="text"
                                                  outlined
                                                  readonly
                                                  dense
                                                  reverse
                                                ></v-text-field>
                                                  -->
                                                </v-col>
                                                <v-col>
                                                  <!--
                                                  <v-btn-toggle
                                                    v-model="columnAlign.vertical"
                                                    class=""
                                                    dense
                                                    group>
                                                    <v-tooltip bottom open-delay="500">
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                          icon
                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          <v-icon color="secondary">mdi-minus</v-icon>
                                                        </v-btn>
                                                      </template>
                                                      <span>
                                                        Decrease width of current border
                                                      </span>
                                                    </v-tooltip>
                                                    <v-tooltip bottom open-delay="500">
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                          icon
                                                          v-bind="attrs"
                                                          v-on="on"
                                                        >
                                                          <v-icon color="secondary">mdi-plus</v-icon>
                                                        </v-btn>
                                                      </template>
                                                      <span>
                                                        Increase width of current border
                                                      </span>
                                                    </v-tooltip>
                                                  </v-btn-toggle>
                                                  -->
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Right
                                              <column-border-component
                                                propertyName="width"
                                                elementName="right border"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                  <!--
                                                <v-text-field
                                                  v-model="borderWidth.right"
                                                  type="text"
                                                  outlined
                                                  readonly
                                                  dense
                                                  reverse
                                                ></v-text-field>
                                                  -->
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col>
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Bottom
                                              <column-border-component
                                                propertyName="width"
                                                elementName="bottom border"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                  <!--
                                                <v-text-field
                                                  v-model="borderWidth.bottom"
                                                  type="text"
                                                  outlined
                                                  readonly
                                                  dense
                                                  reverse
                                                ></v-text-field>
                                                  -->
                                                </v-col>
                                                <v-col>
                                                </v-col>
                                              </v-row>
                                    </v-card-text>
                                  </v-card>
                                            </v-col>
                                            <v-col cols="4">
                                  <v-card
                                    outlined
                                    class="mb-2">
                                    <v-card-text>
                                              <p>Cell Padding</p>
                                              <v-row>
                                                <v-col cols="4" >
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Top
                                              <column-border-component
                                                propertyName="width"
                                                elementName="top padding"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                </v-col>
                                                <v-col cols="4" >
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="4" class="subtitle-2">Left
                                              <column-border-component
                                                propertyName="width"
                                                elementName="left padding"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                </v-col>
                                                <v-col cols="4" >
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Right
                                              <column-border-component
                                                propertyName="width"
                                                elementName="right padding"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                </v-col>
                                              </v-row>
                                              <v-row>
                                                <v-col cols="4" >
                                                </v-col>
                                                <v-col cols="4" class="subtitle-2">Bottom
                                              <column-border-component
                                                propertyName="width"
                                                elementName="bottom padding"
                                                :limits="{ min: 0, max: 5 }"
                                                :initialPropertyValue="5"
                                                :incrementStep="1"
                                                :decrementStep="1"
                                              >
                                              </column-border-component>
                                                </v-col>
                                                <v-col cols="4" >
                                                </v-col>
                                              </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                                <v-col cols="4">
                                  <v-card
                                    outlined
                                    class="mb-2">
                                    <v-card-text>
                                      <div>Row Height</div>
                                      <element-height-component elementName="row" :initialPropertyValue="0" :limits="{ min: 0, max: 100 }"></element-height-component>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item class="d-flex justify-left">
                      <span
                        style="width:50px;"
                        class="text-caption"
                        :class="{ 'text--disabled' : formatMenuVisible }">
                        Column
                      </span>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="insertColumnBefore(key)"
                            :disabled="formatMenuVisible || borderMenuVisible"
                          >
                            <v-icon color="secondary">mdi-table-column-plus-before</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Insert column before
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!canWidenColumnLeft(index) || formatMenuVisible || borderMenuVisible"
                            @click="widenColumnLeft(index)"
                          >
                            <v-icon color="accentGreen">mdi-arrow-expand-left</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Widen column left
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="selectColumn(key)"
                            :disabled="formatMenuVisible || borderMenuVisible"
                          >
                            <v-icon color="success lighten-1">mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Edit column content
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="toggleColumnFormatMenu()"
                            :disabled="borderMenuVisible"
                          >
                            <v-icon color="accentYellow">mdi-format-font</v-icon>
                          </v-btn>
                        </template>
                        <span
                          v-if="!formatMenuVisible">
                          Format cell text
                        </span>
                        <span
                          v-if="formatMenuVisible">
                          Hide Format Cell Text Menu
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="toggleCellBorderMenu()"
                            :disabled="formatMenuVisible"
                          >
                            <v-icon color="accentYellow">mdi-format-line-style</v-icon>
                          </v-btn>
                        </template>
                        <span
                          v-if="!borderMenuVisible">
                          Define cell borders and padding
                        </span>
                        <span
                          v-if="borderMenuVisible">
                          Hide Cell Borders and Padding Menu
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <!-- <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="deleteColumn(key)"
                            :disabled="formatMenuVisible || borderMenuVisible"
                          >
                            <v-icon color="important">mdi-delete</v-icon>
                          </v-btn> -->
                          <v-speed-dial
                            v-model="fab"
                          >
                            <template v-slot:activator>
                              <v-btn
                                v-model="fab"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon color="important">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </template>
                            <v-btn
                              fab
                              dark
                              small
                              color="grey"
                            >
                              NO
                            </v-btn>
                            <v-btn
                              fab
                              dark
                              small
                              color="important"
                              @click="deleteColumn(key)"
                              :disabled="formatMenuVisible || borderMenuVisible"
                            >
                              YES
                            </v-btn>
                          </v-speed-dial>
                        </template>
                        <span>
                          Delete column
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            :disabled="!canWidenColumnRight(index) || formatMenuVisible || borderMenuVisible"
                            @click="widenColumnRight(index)"
                          >
                            <v-icon color="accentGreen">mdi-arrow-expand-right</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Widen column right
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom open-delay="500">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="insertColumnAfter(key)"
                            :disabled="formatMenuVisible || borderMenuVisible"
                          >
                            <v-icon color="secondary">mdi-table-column-plus-after</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Insert column after
                        </span>
                      </v-tooltip>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </span>
      {{ displayContent(column) }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import axios from 'axios'
// import Editor from '@tinymce/tinymce-vue'
import ColumnBorderComponent from '@/components/StructureBuilder/ColumnBorderComponent'
import HorizontalAlignmentComponent from '@/components/StructureBuilder/HorizontalAlignmentComponent'
import VerticalAlignmentComponent from '@/components/StructureBuilder/VerticalAlignmentComponent'
import FontStyleComponent from '@/components/StructureBuilder/FontStyleComponent'
import FontSizeComponent from '@/components/StructureBuilder/FontSizeComponent'
import FontNameComponent from '@/components/StructureBuilder/FontNameComponent'
import ElementHeightComponent from '@/components/StructureBuilder/ElementHeightComponent'
import ColorPickerComponent from '@/components/StructureBuilder/ColorPickerComponent'

export default Vue.extend({
  name: 'AgendaStyleRegionColumn',
  props: {
    row: {},
    columns: {},
    rowData: null
  },
  components: {
    // editor: Editor
    ColumnBorderComponent,
    HorizontalAlignmentComponent,
    VerticalAlignmentComponent,
    FontStyleComponent,
    FontSizeComponent,
    FontNameComponent,
    ElementHeightComponent,
    ColorPickerComponent
  },
  data: () => ({
    tinyMceApiKey: 'ora69qshwjy8esmuw5qdd6d1xqa9gim27mvwhqntnwwpmii9',
    tinyMceConfig: {
      height: 20,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: '',
      statusbar: false,
      inline: true
    },
    minimumColumnWidth: 0.25,
    formatMenuVisible: false,
    borderMenuVisible: false,
    columnAlign: {
      horizontal: '',
      vertical: ''
    },
    fontSizeMin: 8,
    fontSizeMax: 48,
    fontSize: '12',
    fontFamilies: [
      'Algerian',
      'Arial',
      'Calibri',
      'Cambria',
      'Courier',
      'Franklin Gothic',
      'Futura',
      'Helvetica',
      'Rockwell',
      'Times New Roman',
      'Verdana'
    ],
    fontFamily: 'Arial',
    borderWidth: { left: 0, top: 0, right: 0, bottom: 0 },
    borderWidthMax: 5
  }),
  computed: {
    ...mapState('region', ['previewRegions', 'selectedRegion'])
  },
  methods: {
    displayContent (column) {
      if (column.parsedContent !== undefined && column.parsedContent.length > 0) return column.parsedContent
      return column.content
    },
    insertRowAfter (value) {
      console.log(value)
      this.insertRowAtIndex(value)
    },
    async insertRowAtIndex (index) {
      const emptyRow = await this.getEmptyRow()
      const rows = this.selectedRegion[this.type].rows

      const rowVals = Object.values(rows)
      rowVals.splice(index, 0, emptyRow)

      const newRowsObj = {}
      rowVals.forEach((elm, idx) => {
        newRowsObj[idx + 1] = elm
      })

      this.selectedRegion[this.type].rows = newRowsObj
      await this.$store.dispatch('region/setSelectedRegion', this.selectedRegion)
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    async getEmptyRow () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-row')
      return response.data
    },
    toggleColumnFormatMenu () {
      this.formatMenuVisible = !this.formatMenuVisible
    },
    toggleCellBorderMenu () {
      this.borderMenuVisible = !this.borderMenuVisible
    },
    changeColumnMenuContent (columnIndex) {
      // this.$refs.columnMenuContent = 'This is the new content'
      console.log(this.columns[columnIndex])
      // console.log('the column index is: ' + columnIndex)
      // console.log('the content of this column is: ' + this.columns[columnIndex].parsedContent)
      this.columns[columnIndex].style['font-weight'] = 'bold'
      // console.log(this.columns[columnIndex])
      // this.isLastColumn(columnIndex)
    },
    canWidenColumnLeft (columnIndex) {
      return (columnIndex !== 0 && (this.getWidthOfColumnToLeft(columnIndex) > this.minimumColumnWidth))
    },
    canWidenColumnRight (columnIndex) {
      return (!this.isLastColumn(columnIndex) && (this.getWidthOfColumnToRight(columnIndex) > this.minimumColumnWidth))
    },
    isLastColumn (columnIndex) {
      return columnIndex === this.columns.length - 1
    },
    getWidthOfColumnToLeft (columnIndex) {
      return this.columns[columnIndex - 1].width
    },
    getWidthOfColumnToRight (columnIndex) {
      return this.columns[columnIndex + 1].width
    },
    widenColumnLeft (columnIndex) {
      // Never can be the first column

      console.log(this.columns[columnIndex].width)
      // Take the minimum column width off previous column
      this.columns[columnIndex - 1].width = parseFloat(this.columns[columnIndex - 1].width) - this.minimumColumnWidth

      // Add the minimum column width to current column
      this.columns[columnIndex].width = parseFloat(this.columns[columnIndex].width) + this.minimumColumnWidth
      console.log(this.columns[columnIndex].width)
    },
    widenColumnRight (columnIndex) {
      // Never can be the last column
      console.log(this.columns[columnIndex].width)
      // Take the minimum column width off next column
      this.columns[columnIndex + 1].width = parseFloat(this.columns[columnIndex + 1].width) - this.minimumColumnWidth

      // Add the minimum column width to current column
      this.columns[columnIndex].width = parseFloat(this.columns[columnIndex].width) + this.minimumColumnWidth
      console.log(this.columns[columnIndex].width)
    },
    rowHasChanged (rowData) {
      this.$emit('rowChanged', rowData)
    },
    increaseFontSize () {
      if (this.fontSize + 1 <= this.fontSizeMax) {
        this.fontSize++
      }
    },
    decreaseFontSize () {
      if (this.fontSize - 1 >= this.fontSizeMin) {
        this.fontSize--
      }
    },
    increaseBorderWidth (direction) {
      if (this.borderWidth[direction] + 1 <= this.borderWidthMax) {
        this.borderWidth[direction]++
      }
    },
    decreaseBorderWidth (direction) {
      if (this.borderWidth[direction] - 1 > 0) {
        this.borderWidth[direction]--
      }
    }
  }
})
</script>
