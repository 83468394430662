<template>
  <div>
    <v-color-picker
    v-model="currentPropertyValue"
    :hide-canvas="hideCanvas"
    :swatches="swatches"
    :show-swatches="true"
    canvas-height="50"
    :hide-inputs="true"
  ></v-color-picker>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'ColorPickerComponent',
  props: {
    elementName: null,
    initialPropertyValue: { r: 0, g: 0, b: 0 },
    hideCanvas: { default: false }
  },
  data: () => ({
    currentPropertyValue: { r: 0, g: 0, b: 0 },
    swatches: [
      '#ff0000',
      '#FADC00',
      '#00ff00',
      '#0000ff',
      '#000000'
    ].map((x) => [x])
  }),
  methods: {
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
