<template>
  <div>
    <v-btn-toggle
      v-model="currentPropertyValue"
      class=""
      dense
      group>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            value="top"
            small
          >
            <v-icon color="secondary">mdi-format-align-top</v-icon>
          </v-btn>
        </template>
        <span>
          Top-align {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            value="middle"
            small
          >
            <v-icon color="secondary">mdi-format-align-middle</v-icon>
          </v-btn>
        </template>
        <span>
          Middle-align {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            value="bottom"
            small
          >
            <v-icon color="secondary">mdi-format-align-bottom</v-icon>
          </v-btn>
        </template>
        <span>
          Bottom-align {{ elementName }}
        </span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'VerticalAlignmentComponent',
  props: {
    elementName: null,
    initialPropertyValue: { default: 'top' }
  },
  data: () => ({
    currentPropertyValue: null
  }),
  methods: {
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
