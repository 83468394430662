var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn-toggle',{attrs:{"dense":"","group":""},model:{value:(_vm.currentPropertyValue),callback:function ($$v) {_vm.currentPropertyValue=$$v},expression:"currentPropertyValue"}},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"left"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-align-left")])],1)]}}])},[_c('span',[_vm._v(" Left-align "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"center"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-align-center")])],1)]}}])},[_c('span',[_vm._v(" Center "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"right"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-align-right")])],1)]}}])},[_c('span',[_vm._v(" Right-align "+_vm._s(_vm.elementName)+" ")])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","value":"justify"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("mdi-format-align-justify")])],1)]}}])},[_c('span',[_vm._v(" Justify "+_vm._s(_vm.elementName)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }