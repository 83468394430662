<template>
  <div>
    <v-select
      :items="fontFamilies"
      v-model="currentPropertyValue"
      dense
      outlined
    ></v-select>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FontNameComponent',
  props: {
    elementName: null,
    initialPropertyValue: { default: 'Arial' }
  },
  data: () => ({
    fontFamilies: [
      'Algerian',
      'Arial',
      'Calibri',
      'Cambria',
      'Courier',
      'Franklin Gothic',
      'Futura',
      'Helvetica',
      'Rockwell',
      'Times New Roman',
      'Verdana'
    ],
    currentPropertyValue: 'Arial'
  }),
  methods: {
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
