<template>
  <v-dialog persistent
            v-model="showBuilderInProgressModal"
            width="500"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">
      <v-card-title class="headline mb-2">
        You have unsaved changes!
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        Are you sure you want to exit?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green"
          text
          @click="closeModal"
        >
          Return and save
        </v-btn>
        <v-btn
          color="error"
          text
          @click="exitBuilder"
        >
          Exit without saving
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'BuilderInProgressComponent',
  props: {
    showBuilderInProgressModal: {
      required: true,
      default: false,
      type: Boolean
    }
  },
  data: () => ({
    dialog: true
  }),
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    exitBuilder () {
      this.$emit('clearRegion')
    }
  }
})
</script>
