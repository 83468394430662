<template>
  <div>
    <v-btn-toggle
      v-model="currentPropertyValue"
      dense
      group>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="left"
          >
            <v-icon color="secondary">mdi-format-align-left</v-icon>
          </v-btn>
        </template>
        <span>
          Left-align {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="center"
          >
            <v-icon color="secondary">mdi-format-align-center</v-icon>
          </v-btn>
        </template>
        <span>
          Center {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="right"
          >
            <v-icon color="secondary">mdi-format-align-right</v-icon>
          </v-btn>
        </template>
        <span>
          Right-align {{ elementName }}
        </span>
      </v-tooltip>
      <v-tooltip bottom open-delay="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            small
            value="justify"
          >
            <v-icon color="secondary">mdi-format-align-justify</v-icon>
          </v-btn>
        </template>
        <span>
          Justify {{ elementName }}
        </span>
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'HorizontalAlignmentComponent',
  props: {
    elementName: null,
    initialPropertyValue: { default: 'left' }
  },
  data: () => ({
    currentPropertyValue: 0
  }),
  methods: {
    /*
    incrementProperty () {
      if (this.currentPropertyValue + this.incrementStep <= this.limits.max) {
        this.currentPropertyValue++
        this.returnCurrentValue()
      }
    },
    decrementProperty () {
      if (this.currentPropertyValue - this.decrementStep >= this.limits.min) {
        this.currentPropertyValue--
        this.returnCurrentValue()
      }
    },
    returnCurrentValue () {
      console.log('current value:' + this.currentPropertyValue)
      // this.$emit('propertyValueChanges', this.currentPropertyValue)
    }
    */
  },
  created () {
    this.currentPropertyValue = this.initialPropertyValue
  }
})
</script>
