<template>
  <v-dialog
    width="1100"
    v-model="show"
    :persistent="this.$store.state.modalNotCloseOutside"
    :no-click-animation="this.$store.state.modalWithoutAnimation"
  >
    <v-card elevation="0" rounded="0" class="pa-8 veryDarkGrey--text">
      <builder-in-progress-component :showBuilderInProgressModal="showBuilderInProgressModal"
                                     @closeModal="showBuilderInProgressModal = false"
                                     @clearRegion="clearRegion"/>

      <v-btn
        depressed
        color="primary"
        @click="viewRegions"
      >
        VIEW ALL REGIONS (test)
      </v-btn>
      <v-btn
        depressed
        color="secondary"
        @click="parseRegion(this.regions)"
      >
        Parse Regions
      </v-btn>
      <span class="modal-close-btn">
        <v-btn
          icon
          color="grey"
          @click="closeModal"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </span>

      <v-tabs
        v-model="tab"
        color="transparent"
        grow class="d-none"
      >
      </v-tabs>

      <v-tabs-items v-model="tab" class="transparent">

<!--        <v-btn depressed width="250" color="secondary" @click="determineNewWidthOrChange">determineNewWidthOrChange</v-btn>-->

        <v-tab-item key="0">
            <v-card-title class="mb-12" style="height: 70px;">

              <v-row>
                <v-col>
                  <span v-if="builderType === 'agenda'">Design Template for <span class="font-weight-black">{{agendaStyle.name}}</span></span>
                  <span v-if="builderType === 'meeting'">Edit Meeting Draft of <span class="font-weight-black">Meeting Name</span> for <span class="font-weight-black">Name of Board</span></span>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="darkGrey"
                        size="18"
                        class="ml-3"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>
                      Explanation for this area
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col class="d-flex justify-end align-center" v-if="builderType === 'agenda'">
                  <v-switch
                    inset
                    label="Preview"
                    color="secondary"
                    v-model="previewRegions"
                    class="mt-0"
                    hide-details
                  ></v-switch>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        height="40"
                        color="orange lighten-2"
                        class="white--text ml-8"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon size="18">mdi-plus</v-icon> Add Region
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item @click="addRegionDuplicate">
                        <v-list-item-title>Duplicate last region at bottom</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="addRegion">
                        <v-list-item-title>Empty</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>

            </v-card-title>

            <v-card-text class="pa-3 mb-6 overflow-auto">

              <div class="letter-wrapper">
                <v-card
                  elevation="8"
                  class="d-flex flex-column rounded-0 letter-paper"
                  style="padding: 0.5in"
                >
                  <v-tooltip top v-for="(region, index) in regions" :key="index + Math.random()">
                    <template v-slot:activator="{ on, attrs }">
                      <agenda-style-region-component-complete
                        @goToRegion="goToRegion"
                        :regionKey="index"
                        :region="region"
                        :settings="settings"
                        :addRegionMenu="addRegionMenu"
                        :builderType="builderType"
                        :testRegions="testRegions"
                        :attrs="attrs"
                        :on="on"
                        ref="childComponent"
                      />
<!--                      <span style="width: 7.5in; border-bottom: 0.5px solid black !important; opacity: 0.5; padding-top: 5px"></span>-->
                      <span style="padding-top: 2px"></span>
                    </template>
                    <span v-if="region.name">{{  region.name }}</span>
                    <span v-else><i>Region needs a name</i></span>
                  </v-tooltip>

                  <v-card-text v-if="regions.length === 0">
                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Region
                    </v-card>
                  </v-card-text>

                </v-card>
              </div>

            </v-card-text>

            <v-card-actions class="d-flex align-center justify-center">
                <v-spacer></v-spacer>
                <v-btn depressed width="130" @click="updateTemplate">Update</v-btn>
            </v-card-actions>
        </v-tab-item>

        <v-tab-item key="1">

          <v-card-title class="mb-12" style="height: 70px;">
            <v-row>
              <v-col>
                <v-btn
                  depressed
                  icon
                  @click="goToAllRegions"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>Regions</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center" v-if="tabRegion > 0">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRegions"
                  class="mt-0"
                  hide-details
                ></v-switch>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      height="40"
                      color="orange lighten-2"
                      class="white--text ml-8"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon size="18">mdi-plus</v-icon> Add Row
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="addRowDuplicate">
                      <v-list-item-title>Duplicate last row</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="addRow">
                      <v-list-item-title>Empty</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>

          </v-card-title>

          <v-card-text style="min-height: 450px;">

            <v-tabs
              v-model="tabRegion"
              centered
              class="custom-tab mb-10"
              color="secondary"
            >
              <v-spacer></v-spacer>
              <v-tab class="cyan--text">
                <v-icon class="mr-2 cyan--text text-darken-4">mdi-cog</v-icon>Settings
              </v-tab>
              <v-spacer></v-spacer>
              <v-tab class="indigo--text">
                <v-icon class="mr-2 indigo--text text-darken-4">mdi-table-edit</v-icon>Structure
              </v-tab>
              <!--
              <v-tab>End Block</v-tab>
              <v-tab>Item Container</v-tab>
              <v-tab>No Item Text</v-tab>
              -->
              <v-spacer></v-spacer>
            </v-tabs>

            <v-tabs-items v-model="tabRegion">
              <v-tab-item :key="0">
                <v-card flat>
                  <agenda-style-region-settings />
                </v-card>
              </v-tab-item>
              <v-tab-item :key="1">
                <v-card flat>

                  <v-card-text class="pa-3 mb-6 overflow-auto" v-if="checkIfRegionHasType('begin_block')">
                    <v-card class="mb-5 pa-2">
                      <v-row>
                        <v-spacer></v-spacer>
                        <v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                              v-on="on"
                              v-bind="attrs">
                                <v-switch
                                  v-model="showPartsBoundaries"
                                  label="Explode region parts"
                                ></v-switch>
                              </div>
                            </template>
                            <span>
                              Show all region parts below in an explode view.<br>
                              This will help you determine which parts you want to edit and<br>
                              will display region parts that may currently have no content.
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                v-on="on"
                                v-bind="attrs">
                          <v-switch
                            label="Show test item titles"
                          ></v-switch>
                              </div>
                            </template>
                            <span>
                              Replace the item title placeholders with some test data<br>
                              to see how your agenda will look with actual titles in it.
                            </span>
                          </v-tooltip>
                        </v-col>
                        <v-spacer></v-spacer>
                      </v-row>
                      <template
                      v-if="showPartsBoundaries">
                        <div class="text-center">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-3 region-part-legend begin-block"
                                rounded
                                depressed
                                height="50"
                                :ripple=false
                                v-bind="attrs"
                                v-on="on"
                              >
                                Begin Block
                              </v-btn>
                            </template>
                            <span>
                              The <b>Begin Block</b> is shown below outlined in the same color as this chip
                            </span>
                          </v-tooltip>
                          <v-btn-toggle
                            rounded
                            mandatory
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="region-part-legend item-container"
                                  height="50"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="showItemContainer"
                                >
                                  Item Container
                                </v-btn>
                              </template>
                              <span>
                                Show the <b>Item Container</b> part below<br>
                                and hide the <b>No Item Text</b> part.
                              </span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="region-part-legend no-item-text"
                                  height="50"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="showNoItemText"
                                >
                                  No Item Text
                                </v-btn>
                              </template>
                              <span>
                                Show the <b>No Item Text</b> part below<br>
                                and hide the <b>Item Container</b> part.
                              </span>
                            </v-tooltip>
                          </v-btn-toggle>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                class="mx-3 region-part-legend end-block"
                                rounded
                                depressed
                                height="50"
                                :ripple=false
                                v-bind="attrs"
                                v-on="on"
                              >
                                End Block
                              </v-btn>
                            </template>
                            <span>
                              The <b>End Block</b> is shown below outlined in the same color as this chip
                            </span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-card>
                    <div class="letter-wrapper">
                      <v-card
                        elevation="8"
                        class="d-flex flex-column rounded-0 letter-width"
                        style="padding: 0.5in"
                      >
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="begin_block"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                          :class="{ 'region-part-wrapper': showPartsBoundaries, 'begin-block': showPartsBoundaries }"
                        />
                        <v-row v-if="showPartsBoundaries && showItemContainer"><v-col><v-card height="20" elevation="0"></v-card></v-col></v-row>
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="item_container"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                          :class="{ 'region-part-wrapper': showPartsBoundaries, 'item-container': showPartsBoundaries }"
                          v-if="showItemContainer"
                        />
                        <v-row v-if="showPartsBoundaries && showNoItemText"><v-col><v-card height="20" elevation="0"></v-card></v-col></v-row>
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="no_item_text"
                          :addRegionMenu="addRegionMenu"
                          :class="{ 'region-part-wrapper': showPartsBoundaries, 'no-item-text': showPartsBoundaries }"
                          v-if="showNoItemText"
                        />
                        <v-row v-if="showPartsBoundaries"><v-col><v-card height="20" elevation="0"></v-card></v-col></v-row>
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="end_block"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                          :class="{ 'region-part-wrapper': showPartsBoundaries, 'end-block': showPartsBoundaries }"
                        />

                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>
                  </v-card-text>

                </v-card>
              </v-tab-item>
              <!--
              <v-tab-item :key="2">
                <v-card flat>

                  <v-card-text class="pa-3 mb-6 overflow-auto" v-if="checkIfRegionHasType('end_block')">
                    <div class="letter-wrapper">
                      <v-card
                        elevation="8"
                        class="d-flex flex-column rounded-0 letter-width"
                        style="padding: 0.5in"
                      >
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="end_block"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                        />

                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>
                      <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                        Add your First Row
                      </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :key="3">
                <v-card flat>

                  <v-card-text class="pa-3 mb-6 overflow-auto" v-if="checkIfRegionHasType('item_container')">
                    <div class="letter-wrapper">
                      <v-card
                        elevation="8"
                        class="d-flex flex-column rounded-0 letter-width"
                        style="padding: 0.5in"
                      >
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="item_container"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                        />
                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>
                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>
                  </v-card-text>

                </v-card>
              </v-tab-item>
              <v-tab-item :key="4">
                <v-card flat>

                  <v-card-text class="pa-3 mb-6 overflow-auto" v-if="checkIfRegionHasType('no_item_text')">
                    <div class="letter-wrapper">
                        <v-card
                          elevation="8"
                          class="d-flex flex-column rounded-0 letter-width"
                          style="padding: 0.5in"
                        >
                        <agenda-style-region-component-complete
                          @goToRegion="goToRegion"
                          @goToRow="goToRow"
                          type="no_item_text"
                          :addRegionMenu="addRegionMenu"
                          :testRegions="testRegions"
                        />

                      </v-card>
                    </div>
                  </v-card-text>
                  <v-card-text v-else>

                    <v-card class="d-flex justify-center align-center lightGrey--text text-uppercase" height="280" elevation="0">
                      Add your First Row
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              -->
            </v-tabs-items>

          </v-card-text>
        </v-tab-item>

        <v-tab-item key="2">

          <v-card-title class="mb-0" style="height: 70px;">

            <v-row>
              <v-col>
                <v-btn
                  depressed
                  icon
                  @click="goToRegion(null)"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span>Row</span>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="darkGrey"
                      size="18"
                      class="ml-3"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>
                    Explanation for this area
                  </span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex justify-end align-center">
                <v-switch
                  inset
                  label="Preview"
                  color="secondary"
                  v-model="previewRow"
                  class="mt-0"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>

          </v-card-title>

          <agenda-style-region-row :type="getTypeFromTabRegion()"
                                   :selectedRowIndex="selectedRowIndex"
                                   @goToRegion="goToRegion(null)"
                                   :tab="tab"
          />

<!--          <v-card-actions class="d-flex align-center justify-center">-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn depressed width="130" @click="goToRegion">Cancel</v-btn>-->
<!--            <v-btn depressed width="130" color="secondary">Update</v-btn>-->
<!--          </v-card-actions>-->

        </v-tab-item>

      </v-tabs-items>
    </v-card>
  </v-dialog>

</template>

<script>
import Vue from 'vue'
import 'vue-slider-component/theme/default.css'
import axios from 'axios'
import AgendaStyleTemplateBuilder from '@/models/AgendaStyleTemplateBuilder'
import AgendaStyleRegionRow from '@/components/AgendaStyles/Regions/AgendaStyleRegionRow'
import AgendaStyleRegionComponentComplete from '@/components/AgendaStyles/Regions/AgendaStyleRegionComponentComplete'
import AgendaStyleRegionSettings from '@/components/AgendaStyles/Regions/AgendaStyleRegionSettings'
import BuilderInProgressComponent from '@/components/AgendaStyles/Regions/BuilderInProgressComponent'
import { mapGetters, mapState } from 'vuex'
// import { parseRegion } from '@/helpers/templateBuilderHelper'

function initialState () {
  return {
    draft: false,
    version_date: '',
    showBuilderInProgressModal: false,
    selectedRowIndex: Number,
    selectedRow: {},
    agendaStyleRegion: {},
    tab: 0,
    fab: false,
    addRegionMenu: false,
    tabRegion: 0,
    rowColumns: 0,
    regionName: 'Region Sample Name',
    settingsForm: {
      name: '',
      agenda_style_id: '',
      contain_items: false,
      include_minutes: false,
      include_in_agenda: false,
      reset_numbering_each_group: false,
      region: '',
      great: '',
      grand: '',
      parent: '',
      first: ''
    },
    testRegions: [
      'Region 1',
      'Region 2',
      'Region 3',
      'Region 4',
      'Region 5',
      'Region 6',
      'Region 7',
      'Region 8',
      'Region 9',
      'Region 10',
      'Region 11',
      'Region 12',
      'Region 13',
      'Region 14'
    ],
    rowContent: [
      '',
      '',
      '',
      ''],
    columnDots: [0, 1.5, 1.75, 4.5, 7.5],
    sliderOptions: {
      process: false,
      tooltipPlacement: 'bottom',
      adsorb: true,
      clickable: false,
      interval: 0.250,
      max: 7.5,
      min: 0,
      minRange: 0.250
    },
    rowSample: [
      { text: 'Name' },
      { text: 'Administrator' },
      { text: 'Email' },
      { text: 'Workgroups' },
      { text: 'Actions' }
    ],
    settings: {
      margins: {},
      numbering: {},
      font: {}
    },
    templateVersions: {},
    showPartsBoundaries: false,
    showItemContainer: true,
    showNoItemText: false
  }
}

export default Vue.extend({
  name: 'AgendaStyleRegions',
  components: {
    AgendaStyleRegionComponentComplete,
    AgendaStyleRegionRow,
    AgendaStyleRegionSettings,
    BuilderInProgressComponent
  },
  props: {
    agendaStyle: {},
    value: {
      required: true,
      type: Boolean
    },
    builderType: {
      required: true,
      type: String
    }
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapState('region', ['regions', 'triggeredClose', 'contentHasBeenModified']),
    contentHasBeenModified: {
      get () {
        return this.$store.state.region.contentHasBeenModified
      },
      set (value) {
        this.$store.dispatch('region/setContentHasBeenModified', value)
      }
    },
    regions: {
      get () {
        return this.$store.state.region.regions
      },
      set (value) {
        this.$store.dispatch('region/setRegions', value)
      }
    },
    selectedRegion: {
      get () {
        return this.$store.state.region.selectedRegion
      },
      set (value) {
        this.$store.dispatch('region/setSelectedRegion', value)
      }
    },
    previewRegion: {
      get () {
        return this.$store.state.region.previewRegion
      },
      set (value) {
        this.$store.dispatch('region/setPreviewRegion', value)
      }
    },
    previewRegions: {
      get () {
        return this.$store.state.region.previewRegions
      },
      set (value) {
        this.$store.dispatch('region/setPreviewRegions', value)
      }
    },
    previewRow: {
      get () {
        return this.$store.state.region.previewRow
      },
      set (value) {
        this.$store.dispatch('region/setPreviewRow', value)
      }
    },
    show: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    numberingFilter () {
      return this.numberingType.filter(item => item.exclude === undefined)
    }
  },
  methods: {
    testAlert () {
      alert('this is my test alert')
    },
    viewRegions () {
      console.log('ViewRegions', this.$store.state.region.regions)
    },
    goToAllRegions () {
      this.tab = 0
    },
    goToRegion () {
      this.tab = 1
    },
    clearRegion () {
      // this.$children.forEach(child => {
      //   child.$destroy()
      // })

      // const refs = this.$refs.childComponent
      // // console.log('refs', refs)
      // refs.forEach(ref => {
      //   // console.log('ref', ref)
      //   Object.assign(ref.$data, ref.$options.data())
      //   ref.$children.forEach(children => {
      //     console.log('children', children)
      //     const t = children.$destroy()
      //     console.log('children destroy', t)
      //     // Object.assign(children.$data, children.$options.data())
      //   })
      // })

      console.log('CLEAR REGION FUNCTION')
      this.goToAllRegions()
      this.showBuilderInProgressModal = false
      Object.assign(this.$data, initialState())
      this.show = false
      this.$store.dispatch('region/RESET_MODULE')

      // console.log('this regions', this.regions)
      // console.log('this selected Row', this.selectedRow)
      // console.log('this selected region', this.selectedRegion)
    },
    goToRow (row, index) {
      this.selectedRow = row
      this.selectedRowIndex = index
      this.tab = 2
    },
    getRegions () {
      if (this.regions.length > 0) return false
      AgendaStyleTemplateBuilder.find(this.agendaStyle.id).then(response => {
        let template = {}
        if (this.agendaStyle.draft) {
          template = response.draftTemplate
        } else {
          template = response.activeTemplate
        }
        const { regions, draft, version_date, settings } = template
        // console.log('parsedRegions', parseRegion(regions))
        this.$store.dispatch('region/setRegions', regions)
        this.draft = draft
        this.version_date = version_date
        this.settings = settings
        this.goToAllRegions()
      }).catch(error => {
        console.log('agendastyleregion errror', error.message)
      })
    },
    getRegionsRevised () {
      if (this.regions.length > 0) return false
      let template = {}
      // Don't allow deleting draft if status === 'pending'
      if (this.agendaStyle.template_versions.draft_version !== null) {
        template = this.templateVersions[this.agendaStyle.template_versions.draft_version]
        this.version_date = this.agendaStyle.template_versions.draft_version
        this.draft = true
      } else {
        template = this.templateVersions[this.agendaStyle.template_versions.active_version]
        this.version_date = this.agendaStyle.template_versions.active_version
        this.draft = false
      }
      // const { regions, draft, version_date, settings } = template
      const { bookmarks, margins, numbering, font, regions } = template
      // console.log('parsedRegions', parseRegion(regions))
      this.$store.dispatch('region/setRegions', regions)
      // Need to add font to each agenda_style template record and then change this
      this.settings = { margins: margins, numbering: numbering, font: font }
      this.goToAllRegions()
    },
    selectColumn (key) {
      this.rowColumns = key
    },
    async addRow () {
      const tabRegion = this.getTypeFromTabRegion()
      let rows = this.selectedRegion[tabRegion].rows
      if (rows === undefined) {
        this.selectedRegion[tabRegion] = { rows: {} }
        rows = this.selectedRegion[tabRegion].rows
      }
      const emptyRow = await this.getEmptyRow()
      this.$set(this.selectedRegion[tabRegion].rows, Object.keys(rows).length + 1, emptyRow)
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    addRowDuplicate () {
      const tabRegion = this.getTypeFromTabRegion()
      const rows = this.selectedRegion[tabRegion].rows
      if (rows === undefined || rows.length === 0) {
        return false
      }
      const lastRowKey = Object.keys(rows).pop()
      const lastRow = JSON.parse(JSON.stringify(this.selectedRegion[tabRegion].rows[lastRowKey]))
      this.$set(this.selectedRegion[tabRegion].rows, Object.keys(rows).length + 1, lastRow)
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    async getEmptyRow () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-row')
      return response.data
    },
    async getEmptyRegion () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-region')
      return response.data
    },
    async addRegion () {
      const emptyRegion = await this.getEmptyRegion()
      const emptyRegionKey = Object.keys(emptyRegion)[0]
      const emptyRegionData = emptyRegion[emptyRegionKey]
      const regionVals = Object.values(this.regions)
      regionVals.splice(regionVals.length, 0, emptyRegionData)

      const newRegionsObj = {}
      regionVals.forEach((elm, idx) => {
        newRegionsObj[idx + 1] = elm
      })

      this.regions = newRegionsObj
      await this.$store.dispatch('region/setSelectedRegion', emptyRegion[emptyRegionKey])
      await this.$store.dispatch('region/setContentHasBeenModified')
      this.goToRegion()
    },
    addRegionDuplicate () {
      const lastRegionKey = Object.keys(this.regions).pop()
      const lastRegion = JSON.parse(JSON.stringify(this.regions[lastRegionKey]))
      const regionVals = Object.values(this.regions)
      regionVals.splice(regionVals.length, 0, lastRegion)

      const newRegionsObj = {}
      regionVals.forEach((elm, idx) => {
        newRegionsObj[idx + 1] = elm
      })

      this.regions = newRegionsObj
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    },
    okClicked () {
      if (this.tabRegion === 0) {
        this.saveSettings()
      }
      this.show = false
    },
    getTypeFromTabRegion () {
      switch (this.tabRegion) {
        case 1: return 'begin_block'
        case 2: return 'end_block'
        case 3: return 'item_container'
        case 4: return 'no_item_text'
      }
    },
    checkIfRegionHasType (type) {
      if (this.selectedRegion[type] !== undefined && this.selectedRegion[type].rows !== undefined && Object.keys(this.selectedRegion[type].rows).length > 0) {
        return Object.keys(this.selectedRegion[type]).length
      }
      return false
    },
    saveSettings () {
      if (this.builderType === 'agenda') {
        console.log('agendaStyle', this.agendaStyle)

        let modeType = 'create'
        if (this.agendaStyleRegion.id !== undefined) {
          modeType = 'edit'
        }

        this.settingsForm.agenda_style_id = this.agendaStyle.id

        const axiosAction = (modeType === 'create' || modeType === 'duplicate') ? 'post' : 'put'
        const axiosUrl = (modeType === 'edit') ? '/api/agenda-style-template-builder/' + this.agendaStyle.id : '/api/agenda-style-template-builder'

        axios({
          method: axiosAction,
          url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
          data: this.settingsForm
        }).then((response) => {
          console.log('response', response)
          // this.resetModal()
          // this.show = false
          // this.$emit('reload', true)
        }).catch(error => {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        })
        // console.log(this.settingsForm)
      }
      // this.show = false
    },
    getSymbolFromType (value) {
      const obj = this.numberingType.find(o => o.value === value)
      return obj.sample
    },
    closeModal () {
      if (this.contentHasBeenModified) {
        this.showBuilderInProgressModal = true
      } else {
        this.clearRegion()
      }
    },
    updateTemplate () {
      console.log(this.regions)

      const data = {
        regions: this.regions,
        version_date: this.version_date,
        settings: this.settings,
        draft: this.draft
      }
      const axiosUrl = '/api/agenda-style-template-builder/' + this.agendaStyle.id
      axios({
        method: 'put',
        url: process.env.VUE_APP_BACKEND_URL + axiosUrl,
        data
      }).then(response => {
        this.contentHasBeenModified = false
      }).catch(error => {
        console.error(error.message)
      })
    }
  },
  watch: {
    previewRow: function (value) {
      this.$store.dispatch('region/setPreviewRow', value)
    },
    regions: {
      deep: true,
      handler () {
        return this.regions
      }
    },
    show: function (value) {
      if (value && this.regions.length === 0) {
        console.log('getting region now')
        // this.getRegions()
        this.templateVersions = JSON.parse(this.agendaStyle.template_versions.versions)
        this.getRegionsRevised()
      }
    },
    triggeredClose: function (value) {
      console.log('TRIGGERING', value)
    }
  },
  mounted () {
    this.$store.dispatch('region/setBuilderType', this.builderType)

    if (this.builderType === 'agenda') {
      this.$store.dispatch('region/setPreviewRegions', false)
    } else {
      this.$store.dispatch('region/setPreviewRegions', true)
    }
  }
})
</script>
