<template>
  <v-card-text class="mb-6" style="min-height: 450px;">

    <v-row>
      <v-col class="pb-0">
        <v-tabs
          centered
          color="secondary"
          v-model="selectedTab"
        >
          <v-tab v-for="(rowKey, rowValue) in rowContent" :key="rowValue">{{ rowKey.key }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          small-chips
          :items="Object.entries(datetimeVariables)"
          deletable-chips
          :item-text="item => Object.values(item)[1].label"
          :item-value="item => Object.values(item)[0]"
          outlined
          hide-details
          @input="formatSelectedVariable"
          placeholder="DateTime Variables"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          small-chips
          :items="Object.keys(datetimeVariables)"
          :item-text="item =>item.value"
          :item-value="item => item"
          deletable-chips
          outlined
          hide-details
          @input="formatSelectedVariable"
          placeholder="DateTime Formats"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          small-chips
          :items="Object.keys(variableResolutions)"
          deletable-chips
          outlined
          hide-details
          @input="formatSelectedVariable"
          placeholder="Variable Resolutions"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="d-flex mb-8">
      <v-col
        class="veryLightGrey pa-8"
      >
        <v-row>
          <v-col class="py-0">
            <v-textarea
              height="80"
              v-model="textAreaContent"
              class="white mt-0 pt-0 rounded-0"
              hide-details
              outlined
              no-resize
            ></v-textarea>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    <v-row>
      <v-col
      >

        <div class="letter-wrapper">
          <v-card
            elevation="8"
            class="d-flex flex-column rounded-0 letter-width"
            style="padding: 0.5in"
          >
                    <span class="d-flex row-editor mb-6">
                      <div class="d-flex" style="position: relative">
                        <div
                          class="edit-cell d-flex align-center"
                          v-for="(item,key) in selectedRow.columns"
                          ref="rowCol"
                          :key="'col_' + key"
                          @click="selectColumn(key)"
                          :class="{ 'table-preview' : previewRow }"
                          :style="{'width': item.width + 'in'}"
                        >
                          {{ displayContent(item) }}
                          <span class="edit-column" :style="parseInt(selectedTab) + 1 === parseInt(key) ? {opacity:1} : {}">
                            <v-menu
                              offset-y
                              open-on-hover
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  icon
                                  color="transparent"
                                  tile
                                  block
                                  @click="selectColumn(key)"
                                >
                                  <v-icon>mdi-vertical-dots</v-icon>
                                </v-btn>
                              </template>
                              <v-list
                                dense
                              >
                                <v-list-item class="d-flex justify-center">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="insertColumnBefore(key)"
                                      >
                                        <v-icon color="secondary">mdi-table-column-plus-before</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Insert column before
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="selectColumn(key)"
                                      >
                                        <v-icon>mdi-pencil</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Edit column
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="deleteColumn(key)"
                                      >
                                        <v-icon color="important">mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Delete column
                                    </span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="insertColumnAfter(key)"
                                      >
                                        <v-icon color="secondary">mdi-table-column-plus-after</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>
                                      Insert column after
                                    </span>
                                  </v-tooltip>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </span>
                        </div>
                      </div>

                    </span>

            <vue-slider
              v-model="columnDots"
              v-bind="sliderOptions"
              :min="sliderOptions.min"
              :max="sliderOptions.max"
              :interval= "sliderOptions.interval"
              class="custom-slider"
              @change="updateRowColumns"
              v-if="!previewRow"
              :enable-cross="false"
            ></vue-slider>

          </v-card>
        </div>

      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import VueSlider from 'vue-slider-component'
import axios from 'axios'
import { parseColumnContent } from '@/helpers/templateBuilderHelper'

function initialState () {
  return {
    // columnDots: [0, 1.5, 1.75, 4.5, 7.5],
    columnDots: [],
    sliderOptions: {
      process: false,
      tooltipPlacement: 'bottom',
      adsorb: true,
      clickable: false,
      interval: 0.250,
      max: 7.5,
      min: 0,
      minRange: 0.250
    },
    rowColumns: 0,
    rowContent: [],
    rowColumnSelected: 0,
    selectedTab: 0
  }
}

export default Vue.extend({
  name: 'AgendaStyleRegionRow',
  props: ['selectedRowIndex', 'type', 'tab'],
  components: {
    VueSlider
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapState('region', ['selectedRow', 'selectedRegion', 'previewRow', 'triggeredClose']),
    ...mapGetters('regionvariables', ['datetimeVariables', 'datetimeFormats', 'variableResolutions']),
    textAreaContent: {
      get () {
        return this.displayContent(this.selectedRow?.columns[this.selectedTab + 1])
      },
      set (val) {
        if (val && this.selectedRow !== undefined) {
          Vue.set(this.selectedRow.columns[this.selectedTab + 1], 'content', val)
          Vue.set(this.selectedRow.columns[this.selectedTab + 1], 'parsedContent', parseColumnContent(val))
          this.$store.dispatch('region/setContentHasBeenModified')
        }
      }
    }
  },
  methods: {
    testAddRegion () {
      console.log('Add Test Region')
    },
    displayContent (column) {
      if (column.parsedContent !== undefined && column.parsedContent.length > 0) return column.parsedContent
      return column.content
    },
    formatSelectedVariable (event) {
      if (event === null) return false
      this.textAreaContent += '[*' + event + '*]'
    },
    updateRowColumns () {
      const totalCols = this.columnDots.length
      for (let i = 0; i < totalCols; i++) {
        if (i < (totalCols - 1)) {
          const columnWidth = (this.columnDots[i + 1] - this.columnDots[i])
          this.$refs.rowCol[i].style.width = columnWidth + 'in'
          this.selectedRow.columns[i + 1] = Object.assign({}, this.selectedRow.columns[i + 1], { width: columnWidth.toString() })
        } else {
          const columnWidth = (this.sliderOptions.max - this.columnDots[i])
          if ((this.sliderOptions.max - this.columnDots[i]) >= this.sliderOptions.interval) {
            this.$refs.rowCol[i].style.width = columnWidth + 'in'
            this.selectedRow.columns[i + 1] = Object.assign({}, this.selectedRow.columns[i + 1], { width: columnWidth.toString() })
          }
        }
      }
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    setColumnContents () {
      this.columnDots = []
      this.rowContent = []

      this.columnDots.push(0)

      for (const [key, value] of Object.entries(this.selectedRow.columns)) {
        this.rowContent.push({
          key,
          value: value.content
        })
        // this.columnDots.push(parseFloat(value.width) + this.columnDots[key - 1])
        console.log('columnDots: ' + JSON.stringify(this.columnDots))
        this.columnDots = [0.0, 1.5, 2.5, 6.0]
        this.rowContent = [{ key: '0', value: 'first' }, { key: '1', value: 'second' }, { key: '2', value: 'third' }, { key: '3', value: 'fourth' }]
        console.log('rowContent: ' + JSON.stringify(this.rowContent))
      }
    },
    selectColumn (key) {
      this.selectedTab = key - 1
      // this.rowColumns = key
      this.rowColumnSelected = key - 1
    },
    insertColumnBefore (index) {
      this.insertColumnAtIndex(index - 1)
    },
    insertColumnAfter (index) {
      this.insertColumnAtIndex(index)
    },
    async insertColumnAtIndex (index) {
      const columns = this.selectedRow.columns
      const emptyColumn = await this.getEmptyColumn()

      const newWidth = this.determineNewWidthOrChange()
      if (newWidth === 0) {
        // Too many columns, no more space
        console.log('Too Many Columns!')
        return false
      }

      emptyColumn.width = newWidth
      const columnsVals = Object.values(columns)
      columnsVals.splice(index, 0, emptyColumn)

      const newColumnsObj = {}
      columnsVals.forEach((elm, idx) => {
        newColumnsObj[idx + 1] = elm
      })

      this.selectedRow.columns = newColumnsObj
      await this.$store.dispatch('region/setContentHasBeenModified')
    },
    determineNewWidthOrChange () {
      const maxWidth = this.sliderOptions.max
      const intervalWidth = this.sliderOptions.interval
      const columns = this.selectedRow.columns

      let currentTotalWidth = 0
      for (const column in columns) {
        if (Object.prototype.hasOwnProperty.call(columns, column)) {
          const curColumn = columns[column]
          currentTotalWidth += parseFloat(curColumn.width)
        }
      }

      // Save Zone
      if (currentTotalWidth < maxWidth) {
        // console.log('currentTotalWidth', currentTotalWidth)
        // console.log('maxWidth', maxWidth)
        // console.log('NEW WIDTH', currentTotalWidth - maxWidth)
        return maxWidth - currentTotalWidth
      }

      let hasAnotherColumnSizeBeenModified = false

      for (const column in columns) {
        if (Object.prototype.hasOwnProperty.call(columns, column)) {
          const curColumn = columns[column]
          if (curColumn.width >= intervalWidth * 2 && hasAnotherColumnSizeBeenModified === false) {
            const newWidth = parseFloat(curColumn.width) - intervalWidth
            this.selectedRow.columns[column] = Object.assign({}, curColumn, { width: newWidth })
            hasAnotherColumnSizeBeenModified = true
            break
          }
        }
      }

      if (hasAnotherColumnSizeBeenModified) {
        return 0.25
      }

      return 0
    },
    async getEmptyColumn () {
      const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/agenda-style-template-builder/empty-column')
      return response.data
    },
    deleteColumn (index) {
      const maxWidth = this.sliderOptions.max
      const intervalWidth = this.sliderOptions.interval
      const columns = JSON.parse(JSON.stringify(this.selectedRow.columns))
      const columnVals = Object.values(columns)

      columnVals.splice(index - 1, 1)

      // Since theres only 1 column left, just remove the row.
      if (columnVals.length === 0) {
        this.removeRow()
        this.$emit('goToRegion')
        return false
      }

      let currentTotalWidth = 0
      columnVals.forEach(elm => {
        currentTotalWidth += parseFloat(elm.width)
      })

      let totalNeeded = maxWidth - currentTotalWidth
      while (totalNeeded !== 0) {
        for (let i = 0; i < columnVals.length; i++) {
          if (totalNeeded === 0) break
          columnVals[i].width = parseFloat(columnVals[i].width) + intervalWidth
          totalNeeded = totalNeeded - intervalWidth
        }
      }

      const newColumnsObj = {}
      columnVals.forEach((elm, idx) => {
        newColumnsObj[idx + 1] = elm
      })

      this.selectedTab = 0
      this.selectedRow.columns = Object.assign({}, newColumnsObj)
      this.$store.dispatch('region/setContentHasBeenModified')
    },
    removeRow () {
      Vue.delete(this.selectedRegion[this.type].rows, this.selectedRowIndex)
      this.$store.dispatch('region/setContentHasBeenModified')
    }
  },
  watch: {
    selectedRow: {
      handler () {
        if (this.selectedRow !== undefined) {
          this.setColumnContents()
        }
      },
      deep: true
    },
    tab: {
      handler () {
        if (this.tab !== 2) {
          this.rowColumnSelected = 0
          this.selectedTab = 0
        }
      }
    },
    previewRow: function (value) {
      this.$store.dispatch('region/setPreviewRow', value)
    },
    triggeredClose: function (value) {
      console.log('Region Row TiggeredClose', value)
    }
  },
  beforeMount () {
    // this.setColumnContents()
  },
  beforeDestroy () {
    console.log('DESTROYING ROW!!!!!!!!!!!!!!!!!!!!!')
  }
})
</script>
